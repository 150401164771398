import React from 'react'
import styles from './copyright.module.css'

export class Copyright extends React.Component {
  render() {
    return (
      <aside className={styles.copyright}>
        <p>
          © 2020 Copyright Jaime Gonzalez Garcia{' '}
          <span className={styles.star}>*</span>. All rights reserved.
        </p>
        <p>
          This site is built with <a href="gatsbyjs.org">Gatsby</a> which is{' '}
          <strong>insanely</strong> awesome.
        </p>
        <p className={styles.description}>
          {' '}
          <span className={styles.star}>*</span>
          Half-elven ranger, deadly archer, master of the two-handed sword and
          dual scimitar wielder, protector of Veleiron, Falcon of the Crepuscle,
          silent death of the swamps of Zulk....
        </p>
      </aside>
    )
  }
}
