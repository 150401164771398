import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'
import './font-face.css'
import { Arcana } from './arcana/arcana'
import { Copyright } from './copyright/copyright'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        {
          // <Header siteTitle={data.site.siteMetadata.title} />
        }
        <Arcana />
        <section className="content">
          {children}
          <Copyright />
        </section>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
